import React from "react"

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Helmet } from 'react-helmet'

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import IconRocket from "../images/icons/rocket.svg"

export const projectsTileImg = graphql`
  fragment projectsTileImg on File {
    childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1032)
    }
  }
`
export const projectImg = graphql`
  fragment projectImg on File {
    childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1032)
    }
  }
`

export const query = graphql`
    query {
        csr: file(relativePath: { eq: "portfolio/portfolio-tile-csr.png" }) {
            ...projectsTileImg
        }
        westpac: file(relativePath: { eq: "portfolio/portfolio-tile-westpac.png" }) {
            ...projectsTileImg
        }
        deliveries: file(relativePath: { eq: "portfolio/portfolio-tile-deliveries.jpg" }) {
            ...projectsTileImg
        }
        launchpad: file(relativePath: { eq: "portfolio/portfolio-tile-launchpad.png" }) {
            ...projectsTileImg
        }
        growth: file(relativePath: { eq: "portfolio/portfolio-tile-growth.png" }) {
            ...projectsTileImg
        }
        soundwave: file(relativePath: { eq: "portfolio/portfolio-tile-soundwave.png" }) {
            ...projectsTileImg
        }
    }
`

const ProjectsPage = ({ data }) => (
  <>
    <Seo title="Projects - Scott Luxford" />
    <Helmet>
      <body className="projects" />
    </Helmet>
    <Header />
    <main>

        <h1 className="heading-offset"><img role="presentation" src={IconRocket} /> Projects</h1>

        <div className="projects-list">
            <article>
                <Link className="article-link" to="/projects/csr">
                    <GatsbyImage image={getImage(data.csr)} alt="CSR and CSR Connect logos" />
                    <h4>CSR Connect</h4>
                </Link>
            </article>
            <article>
                <Link className="article-link" to="/projects/westpac">
                    <GatsbyImage image={getImage(data.westpac)} alt="Westpac logo on a blank background" />
                    <h4>Westpac OTP</h4>
                </Link>
            </article>
            <article>
                <Link className="article-link" to="/projects/deliveries">
                    <GatsbyImage image={getImage(data.deliveries)} alt="Hand holding a smartphone displaying a delivery tracking map" />
                    <h4>CSR Connect - Deliveries</h4>
                </Link>
            </article>
            <article>
                <Link className="article-link" to="/projects/launchpad">
                    <GatsbyImage image={getImage(data.launchpad)} alt="Cartoon of rocket launching into orbit of a planet" />
                    <h4>CSR Connect - Launchpad</h4>
                </Link>
            </article>
            <article className="article-soon">
                <div className="article-link">
                    <GatsbyImage image={getImage(data.growth)} alt="A retro computer display showing a glowing green conical flask containing an experiment" />
                    <h4>CSR Connect - Growth</h4>
                </div>
            </article>
            <article className="article-soon">
                <div className="article-link">
                    <GatsbyImage image={getImage(data.soundwave)} alt="Addition formula of the logos of Slack and Spotify Slack logo equalling a robots head" />
                    <h4>Soundwave - Office DJ</h4>
                </div>
            </article>
        </div>

    </main>
    <Footer />
  </>
)

export default ProjectsPage
